
// import { mapGetters } from 'vuex'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ProductsCard',
  filters: {
    shortDescription (value) {
      if (value && value.length > 60) {
        return value.substring(0, 60) + '...'
      } else {
        return value
      }
    }
  },
  props: {
    cart: {
      type: Boolean,
      default: false
    },
    ind: {
      type: Number,
      default: null
    },
    qty: {
      type: Number,
      default: null
    },
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      timeOut: null
    }
  },
  computed: {
    ...mapGetters({
      checkProd: 'cart/checkProd',
      prodPrice: 'cart/prodPrice',
      findCatById: 'findCatById',
      getRealPrice: 'getRealPrice'
    }),
    selected () {
      return this.checkProd(this.product)
    }
  },
  methods: {
    ...mapActions({
      updateCart: 'cart/updateCart',
      removeItemInCart: 'cart/removeItemInCart'
    }),
    addItem () {
      const order = {
        item: Object.assign({}, this.product),
        qty: 1,
        isAdd: true
      }
      this.updateCart(order)
      this.$store.commit('SHOW_Cart', true)
      this.$notify.show({
        text: 'Producto Agregado',
        type: 'success',
        closeWait: 2000
      })
    },
    removeItem () {
      const vm = this
      this.removeItemInCart({
        item: vm.product
      })
      if (this.$store.state.cart.cartItemList.length === 0) {
        this.$store.commit('SHOW_Cart', false)
      }
    },
    updateQuantity (event) {
      if (this.timeOut) { clearTimeout(this.timeOut) }
      this.timeOut = setTimeout(() => {
        const vm = this
        this.updateCart({
          item: vm.product,
          qty: parseInt(event.target.value),
          isAdd: false
        })
      }, 1200)
    },
    // delCartProd (ind) {
    //   this.$store.dispatch('removeItem', ind)
    //   if (this.$store.state.storeCart.length === 0) {
    //     this.$store.dispatch('showCart')
    //   }
    // },
    showProd (product) {
      this.$store.commit('SEL_ProdModal', product)
      this.$store.commit('SHOW_Modal', true)
    },
    findCategory (category) {
      const cate = this.findCatById(Number(category))
      return this.passURI(cate.name)
    },
    // findCategoryId (category) {
    //   for (let i = 0; i < this.$store.state.categories.length; i++) {
    //     if ((isNaN(category) ? this.passURI(this.$store.state.categories[i].name) : this.$store.state.categories[i].id) === category) {
    //       return i
    //     }
    //   }
    //   return -1
    // },
    passURI (uri) {
      return uri.toLowerCase().replace(/ /g, '-')
    }
  }
}
