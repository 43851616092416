export const state = () => ({
  banners: [],
  features: [],
  categories: [],
  client: {},
  selectedProd: {},
  showModal: false,
  showCart: false,
  dollarPrice: false,
  exchange: {},
  orders: [],
  products: []
})

export const mutations = {
  SET_Products (state, data) {
    state.products = data
  },
  SET_Categories (state, data) {
    state.categories = data
  },
  SET_Features (state, data) {
    state.features = data
  },
  SET_Exchange (state, data) {
    state.exchange = data
  },
  SET_Banners (state, data) {
    state.banners = data
  },
  SET_DollarPrice (state) {
    state.dollarPrice = !state.dollarPrice
  },
  SET_Orders (state, data) {
    state.orders = data
  },
  INIT_SET_Store (state) {
    if (localStorage.getItem('cartItemList')) {
      state.cart.cartItemList = JSON.parse(localStorage.getItem('cartItemList'))
    }
  },
  ADD_Item (state, prod) {
    prod.qty = 1
    state.storeCart.push(prod)
    // const mobCartBody = document.querySelector('.mobCartBody')
    // mobCartBody.scrollLeft = mobCartBody.scrollWidth
  },
  SEL_ProdModal (state, prod) {
    state.selectedProd = prod
  },
  SHOW_Cart (state, force) {
    state.showCart = force ? true : !state.showCart
  },
  SHOW_Modal (state, act) {
    state.showModal = act != null ? act : !state.showModal
  },
  SET_AUTH (state, { user, loggedIn }) {
    state.auth.user = user
    state.auth.loggedIn = loggedIn
  }
}

export const actions = {
  async nuxtServerInit ({ commit }, { req }) {
    let user = null
    let loggedIn = false
    // if (req.headers.cookie) {
    if (this.$auth.$storage.getCookie('user')) {
      try {
        user = this.$auth.$storage.getCookie('user')
        loggedIn = true
      } catch (err) {
        user = null
      }
    }
    // console.log(auth)
    await commit('SET_AUTH', { user, loggedIn }) // set state auth
  },
  async GET_Products ({ commit }) {
    const { data } = await this.$axios.get('/products?simpleList=true')
    commit('SET_Products', data)
  },
  async GET_WebsiteData ({ commit }) {
    const { data } = await this.$axios.get('/website')
    commit('SET_Categories', data.categories)
    commit('SET_Features', data.features)
    commit('SET_Exchange', data.exchange)
    commit('SET_Banners', data.banners)
  },
  async GET_Orders ({ commit }) {
    // const { data } = await this.$axios.get('services/wholesale/service_orders.php?i=grid&e=' + this.$auth.getToken('local'))
    const { data } = await this.$axios.get('/orders')
    commit('SET_Orders', data)
  }
}

export const getters = {
  categories: () => {
    return state.categories
  },
  getRealPrice: (state) => {
    const moneyFormat = Intl.NumberFormat(state.dollarPrice ? 'en-US' : 'es-VE', { style: 'currency', currency: state.dollarPrice ? 'USD' : 'VES', currencyDisplay: 'symbol' })
    return (price) => {
      return moneyFormat.format(state.dollarPrice ? price : (price * state.exchange.rate))
    }
  },
  getGroupedAttributes: (state) => {
    return (product) => {
      let grouped = {}
      if (product.attributes) {
        grouped = product.attributes.reduce(function (r, a) {
          r[a.feature_id] = r[a.feature_id] || []
          r[a.feature_id].push(a)
          return r
        }, Object.create({}))
      }
      return grouped || []
    }
  },
  getFeatureById: (state) => {
    return (feature) => {
      return state.features.find(elm => elm.id === Number(feature))
    }
  },
  findCatById: (state) => {
    return (category) => {
      return state.categories.find(elm => elm.id === Number(category))
    }
  },
  findCatByName: (state) => {
    return (category) => {
      return state.categories.find(elm => category === elm.name.toLowerCase().replace(/ /g, '-'))
    }
  },
  findCatByProdId: (state) => {
    return (id) => {
      const prod = state.products.find(elm => elm.id === id)
      return prod.id_categoria.toLowerCase().replace(/ /g, '-')
    }
  },
  findOrderById: (state) => {
    return (id) => {
      return state.orders.find(elm => elm.id === id)
    }
  },
  getFormData: (state) => {
    return (object) => {
      const formData = new FormData()
      Object.entries(object).forEach(([key, value]) => { formData.append(key, value) })
      return formData
    }
  }
}
